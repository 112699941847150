<template>
  <div id="Yahoo">
    <template v-if="$route.name === 'Yahoo'">
      <img
        v-if="$store.state.isMobile"
        class="bannerImg"
        src="@/assets/store/YahooBannerMobile.png"
        alt=""
      />
      <template v-if="!$store.state.isMobile">
        <div class="MainContainer">
          <div class="bannerBlock">
            <img
              ref="banner"
              class="banner"
              src="@/assets/store/YahooBanner.png"
              alt=""
              @load="bannerLoadDone"
            />
            <img class="logo" src="@/assets/store/YahooLogo.png" alt="" />
            <div class="logoText" v-if="isBannerLoadDone"> 雅虎购物网 </div>
            <div class="infoBlock" v-if="isBannerLoadDone">
              雅虎日本购物是雅虎日本旗下的线上购物商城，平台商品种类齐全，包括男女时装、食品、家电、电脑、照相机、室内装饰、生活杂货、体育用品、化妆品、婴儿用品、汽车配件、书籍等等。
            </div>
          </div>
        </div>
      </template>

      <div class="MainContainer">
        <ChooseType @click="ChooseTypeClick"></ChooseType>
      </div>
    </template>

    <router-view></router-view>
  </div>
</template>

<script>
  import base from './StoreBase.js';
  import ChooseType from '@/components/Store/ChooseType';
  export default {
    extends: base,
    name: 'Yahoo',
    components: {
      ChooseType,
    },
    methods: {
      ChooseTypeClick(param) {
        this.$router.push({ name: 'YahooStoreList', query: { type: param.name } });
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import './index.scss';
  #Yahoo {
    background: url('~@/assets/bg.png');
    .bannerBlock {
      .logoText {
        color: #000000;
      }
      .infoBlock {
        color: white;
      }
    }
  }
</style>
